import localize from 'localize';

const errorMessage = localize({
  sv: 'Båda fälten måste innehålla samma lösenord.',
  en: 'Must match password exactly.'
});
export default function Setup(passwordConfirmationField, passwordField) {
  return () => {
    if (passwordConfirmationField.value != passwordField.value) {
      passwordConfirmationField.setCustomValidity(errorMessage);
    } else {
      passwordConfirmationField.setCustomValidity('');
    }
  }
}
