import * as rh from './_responseHandlers';
import * as tmy from './_tmyHelpers';
import * as authToken from '../authToken';

import * as responses from 'tummylab-protobuf/js/api.tmy.users.v1/responses_pb';
import * as requests from 'tummylab-protobuf/js/api.tmy.users.v1/requests_pb';

const API_NAME = "tmy.users.v1";

export function getUsers() {
  return tmy.get("/users", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Users.V1.Responses.GetUsers", responses.GetUsers));
}

export function patchLoginRestoration(authToken, newPassword) {
  const responseClass = responses.PatchLoginRestorations;

  const msg = new requests.PatchLoginRestorations();
  msg.setNewPassword(newPassword);

  return tmy.patch("/login_restorations", msg.serializeBinary(), {
    authToken: authToken,
    apiName: API_NAME,
    messageName:  "API.TMY.Users.V1.Requests.PatchLoginRestorations"
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Users.V1.Responses.PatchLoginRestorations", responseClass))
    .then((resp) => {
      switch(resp.getResultCase()) {
        case responseClass.ResultCase.USER_UUID:
          return resp;
        break;
        case responseClass.ResultCase.ERROR: {
          const err = new Error("Change denied");
          err.userDisplayableErrorMessage = resp.getError().getErrorsList().join("\n");
          err.response = resp;
          throw err;
        }; break;
        default: {
          const err = new Error("Unknown result");
          err.response = resp;
          throw err;
        }; break;
      }
    });
}

export function patchUsers(message) {
  const responseClass = responses.PatchUsers;

  return tmy.patch("/users", message.serializeBinary(), {
    authToken: authToken.get(),
    apiName: API_NAME,
    messageName:  "API.TMY.Users.V1.Requests.PatchUsers"
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Users.V1.Responses.PatchUsers", responseClass))
    .then((resp) => {
      switch(resp.getResultCase()) {
        case responseClass.ResultCase.USER_UUID:
          return resp;
        break;
        case responseClass.ResultCase.ERROR: {
          const err = new Error("Change denied");
          err.userDisplayableErrorMessage = resp.getError().getErrorsList().join("\n");
          err.response = resp;
          throw err;
        }; break;
        default: {
          const err = new Error("Unknown result");
          err.response = resp;
          throw err;
        }; break;
      }
    });
}

export function patchUsers_newUsername(userUuid, newUsername) {
  const msg = new requests.PatchUsers();
  msg.setUuid(userUuid);
  msg.setHasNewUsername(true);
  msg.setNewUsername(newUsername);

  return patchUsers(msg);
}

export function patchUsers_newPassword(userUuid, newPassword) {
  const msg = new requests.PatchUsers();
  msg.setUuid(userUuid);
  msg.setHasNewPassword(true);
  msg.setNewPassword(newPassword);

  return patchUsers(msg);
}

export function patchUsers_newEmail(userUuid, newEmail) {
  const msg = new requests.PatchUsers();
  msg.setUuid(userUuid);
  msg.setHasNewEmail(true);
  msg.setNewEmail(newEmail);

  return patchUsers(msg);
}

export function postLogin(username, password, platformToken) {
  const msg = new requests.PostLogin();
  msg.setUsername(username);
  msg.setPassword(password);
  msg.setPlatformToken(platformToken);

  return tmy.post("/login", msg.serializeBinary(), {
    apiName: API_NAME,
    messageName: "API.TMY.Users.V1.Requests.PostLogin"
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Users.V1.Responses.PostLogin", responses.PostLogin));
}

export function getLogin() {
  return tmy.get("/login", {
    apiName: API_NAME,
    authToken: authToken.get().get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Users.V1.Responses.GetLogin", responses.GetLogin));
}

export function postUsers(username, password, email, activationCode, createdAt, regionDalarnaSurveyAnswers, isOnlineSignup, onlineSignupSource) {
  const responseClass = responses.PostUsers;

  const msg = new requests.PostUsers();
  msg.setUsername(username);
  msg.setPassword(password);
  msg.setHasEmail(typeof email === 'string');
  msg.setEmail(email);
  msg.setCreatedAt(createdAt);
  msg.setHasActivationCode(typeof activationCode === 'string');
  msg.setActivationCode(activationCode);

  if (regionDalarnaSurveyAnswers) {
    msg.setIncludesRegionDalarnaSurveyAnswers(true);
    msg.setRegionDalarnaSurveyAnswers(regionDalarnaSurveyAnswers);
  }

  if (isOnlineSignup) {
    msg.setIsOnlineSignup(true);

    if (onlineSignupSource) {
      msg.setOnlineSignupSource(onlineSignupSource);
    }
  }

  return tmy.post("/users", msg.serializeBinary(), {
    apiName: API_NAME,
    messageName: "API.TMY.Users.V1.Requests.PostUsers"
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Users.V1.Responses.PostUsers", responseClass))
    .then((resp) => {
      switch(resp.getResultCase()) {
        case responseClass.ResultCase.USER_UUID:
          return resp;
        break;
        case responseClass.ResultCase.ERROR: {
          const err = new Error("User creation failed");
          err.userDisplayableErrorMessage = resp.getError().getErrorsList().join("\n");
          err.response = resp;
          throw err;
        }; break;
        default: {
          const err = new Error("Unknown result");
          err.response = resp;
          throw err;
        }; break;
      }
    });
}

export function patchUserResearchProjectProfiles(projectID, name, phone, address) {
  const msg = new requests.PatchUserResearchProjectProfiles();
  msg.setProjectId(projectID);
  msg.setName(name);
  msg.setPhone(phone);
  msg.setAddress(address);

  return tmy.patch("/user_research_project_profiles", msg.serializeBinary(), {
    authToken: authToken.get(),
    apiName: API_NAME,
    messageName:  "API.TMY.Users.V1.Requests.PatchUserResearchProjectProfiles"
  })
    .then(rh.checkStatus);
}
