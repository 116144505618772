import template from './index.html';

import localize from 'localize';

export default function Setup(userData) {
  const container = document.createElement("div");
  container.innerHTML = template({
    username: userData.getUsername(),
    t: localize({
      en: {
        title: "Your account is activated!",
        yourUsernameIs: "You username is",
        instruction: "Download Tummy Lab from App Store/Google Play, and sign in using your username and password.",
        whatNowTitle: "What will happen after you’ve downloaded the app?",
        whatNowIntro: "Once you’ve started the app and signed in, you’ll be asked to answer a few questions. After that, the program will start:",
        whatNowItems: [
          "Each day you will watch a 5 to 15 minute educational film. After watching, you will occasionally be asked to complete tasks based on the films content.",
          "During the program you will register your food and drink intake, your symptoms and your bowel movements.",
          "You can also register other factors that you feel impact your IBS. Such as sleep, exercise, medicine, supplements and if relevant, your period.",
          "Should you fail to complete your daily tasks in Tummy Lab, you will always be able to continue where you left off the next day."
        ],
        whatNowSendOff: "Good luck!"
      },
      sv: {
        title: "Ditt konto är aktiverat!",
        yourUsernameIs: "Ditt användarnamn är",
        instruction: "Ladda ner Tummy Lab från App Store/Google Play, och logga in med ditt användarnamn och lösenord.",
        whatNowTitle: "Vad händer när du laddat ner appen?",
        whatNowIntro: "När du startat appen och loggat in, så kommer du först svara på några frågor. Därefter påbörjas programmet:",
        whatNowItems: [
          'Varje dag kommer du se en utbildningsfilm på mellan 5 och 15 minuter. Vissa dagar kommer du få uppgifter knutna till vad du lärt dig.',
          'Du kommer även att registrera dina mat- och dryckesintag, om du känner av några symtom samt dina toalettbesök.',
          'Det är även bra att registera hur du sover, om du tränar och andra faktorer du känner påverkar din IBS.',
          'Skulle du inte hinna med Tummy Lab en dag, så kan du återupta programmet dagen efteråt.'
        ],
        whatNowSendOff: "Lycka till!"
      }
    })
  });
  return container;
}
