import template from './index.html';

import { patchUsers_newPassword } from 'api/tmyUsersV1';

import localize from 'localize';

import PasswordConfirmationValidator from 'formValidator/passwordConfirmation'

import ErrorNotification from 'components/notification/error';
import SuccessNotification from 'components/notification/success';

export default function Setup(userData) {
  const container = document.createElement("div");
  container.innerHTML = template({
    username: userData.getUsername(),
    t: localize({
      sv: {
        title: 'Ändra lösenord',
        username: 'Användarnamn',
        password: 'Lösenord',
        passwordConfirmation: 'Lösenordsbekräftelse',
        submit: 'Ändra'
      },
      en: {
        title: 'Edit Password',
        username: 'Username',
        password: 'Password',
        passwordConfirmation: 'Password confirmation',
        submit: 'Set password'
      }
    })
  });

  const form = container.querySelector("form");
  const passwordField = form.querySelector("input[name=password]");
  const passwordConfirmationField = form.querySelector("input[name=password_confirmation]");

  const passwordConfirmationValidator = PasswordConfirmationValidator(passwordConfirmationField, passwordField);
  passwordConfirmationField.addEventListener("change", passwordConfirmationValidator);
  passwordField.addEventListener("change", passwordConfirmationValidator);

  const errorContainer = container.querySelector(".error-container");
  form.addEventListener("submit", async (ev) => {
    ev.preventDefault();

    errorContainer.removeAllChildren();

    passwordConfirmationField.disabled = true;
    passwordField.disabled = true;

    const button = container.querySelector("button");
    button.disabled = true;
    button.classList.add("is-loading");

    try {
      await patchUsers_newPassword(userData.getUuid(), passwordField.value);
      const notice = SuccessNotification(localize({
        en: "Your password is updated!",
        sv: "Ditt lösenord är uppdaterat!"
      }), false);
      form.parentNode.replaceChild(notice, form);
    } catch (err) {
      let errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Something went wrong, please try again.",
        sv: "Något gick fel, var god försök igen."
      });

      errorContainer.appendChild(ErrorNotification(errorMsg, true));
      passwordConfirmationField.disabled = false;
      passwordField.disabled = false;
      button.disabled = false;
      button.classList.remove("is-loading");
    }
  });
  return container;
}
