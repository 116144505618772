Node.prototype.removeAllChildren = function() {
  while (this.hasChildNodes()) {
    this.removeChild(this.firstChild);
  }
}

Node.prototype.replaceChildren = function(newChild) {
  this.removeAllChildren();
  this.appendChild(newChild);
}
