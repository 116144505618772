import template from './index.html';

import { patchUsers_newEmail } from 'api/tmyUsersV1';

import localize from 'localize';

import ErrorNotification from 'components/notification/error';
import SuccessNotification from 'components/notification/success';

export default function Setup(userData) {
  const container = document.createElement("div");
  container.innerHTML = template({
    username: userData.getUsername(),
    email: userData.getEmail(),
    t: localize({
      sv: {
        title: 'Ändra email',
        username: 'Användarnamn',
        exampleEmail: 'mail@example.com',
        submit: 'Ändra'
      },
      en: {
        title: 'Edit Email',
        username: 'Username',
        exampleEmail: 'mail@example.com',
        submit: 'Set email'
      }
    })
  });

  const form = container.querySelector("form");
  const errorContainer = container.querySelector(".error-container");
  form.addEventListener("submit", async (ev) => {
    ev.preventDefault();

    errorContainer.removeAllChildren();

    const emailField = form.querySelector("input[name=email]");
    emailField.disabled = true;

    const button = container.querySelector("button");
    button.disabled = true;
    button.classList.add("is-loading");

    try {
      await patchUsers_newEmail(userData.getUuid(), emailField.value);
      const notice = SuccessNotification(localize({
        en: "Your email is updated!",
        sv: "Din email är uppdaterad!"
      }), false);
      form.parentNode.replaceChild(notice, form);
    } catch (err) {
      let errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Something went wrong, please try again.",
        sv: "Något gick fel, var god försök igen."
      });

      errorContainer.appendChild(ErrorNotification(errorMsg, true));
      emailField.disabled = false;
      button.disabled = false;
      button.classList.remove("is-loading");
    }
  });
  return container;
}
