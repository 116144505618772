import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';

import Bugsnag from '@bugsnag/browser';

import '@fortawesome/fontawesome-free/js/all.js';
import './index.scss';

import './extensions.js';

import { getUsers } from 'api/tmyUsersV1';
import * as authToken from 'authToken';
import localize from 'localize';

import PageRestorePassword from 'pages/restore_password';
import PageActivateAccount from 'pages/activate_account';
import PageActivateAccountDalarna from 'pages/activate_account_dalarna';
import PageEnkatDalarna from 'pages/enkat_dalarna';
import PageAccountActivated from 'pages/account_activated';
import PageAccountActivatedDalarna from 'pages/account_activated_dalarna';
import PageEditUsername from 'pages/edit_username';
import PageEditPassword from 'pages/edit_password';
import PageEditEmail from 'pages/edit_email';
import PageLogin from 'pages/login';
import PageIndex from 'pages/index';

import ErrorNotification from 'components/notification/error';

export function navigateTo(page) {
  const container = document.querySelector(".page-container");
  refreshContent(container, page);
}

function refreshContent(container, page, replaceState = false) {
  if (replaceState) {
    window.history.replaceState({page: page}, document.title, document.location);
  } else {
    window.history.pushState({page: page}, document.title, "?page=" + page);
  }

  container.removeAllChildren();

  const authRequireItems = document.querySelectorAll("[data-hidden_unless='signed_in']");
  if (authToken.get()) {
    authRequireItems.forEach(i => i.classList.remove('is-hidden'));
  } else {
    authRequireItems.forEach(i => i.classList.add('is-hidden'));
  }

  switch (page) {
    case "activate_account":
      container.replaceChildren(PageActivateAccount((newAuthToken) => {
        authToken.set(newAuthToken);
        refreshContent(container, "account_activated", true);
      }));
      return;
    break;
    case "activate_account_dalarna":
      container.replaceChildren(PageActivateAccountDalarna((newAuthToken) => {
        authToken.set(newAuthToken);
        refreshContent(container, "account_activated_dalarna", true);
      }));
      return;
    break;
    case "enkat_dalarna":
      container.replaceChildren(PageEnkatDalarna());
      return;
    break;

    case "restore_password":
      container.replaceChildren(PageRestorePassword());
      return;
    break;
  }

  let currentAuthToken = null;
  try {
    currentAuthToken = authToken.getAndThrowIfExpired();
  } catch(err) {
    authToken.set(null);
    container.replaceChildren(ErrorNotification(err, true));
  }

  if (!currentAuthToken) {
    container.replaceChildren(PageLogin((newAuthToken) => {
      authToken.set(newAuthToken);
      refreshContent(container, page, true);
    }));

    return;
  }

  getUsers().then((userData) => {
    switch (page) {
      case "edit_email":
        container.replaceChildren(PageEditEmail(userData));
      break;
      case "edit_username":
        container.replaceChildren(PageEditUsername(userData));
      break;
      case "edit_password":
        container.replaceChildren(PageEditPassword(userData));
      break;
      case "account_activated":
        container.replaceChildren(PageAccountActivated(userData));
      break;
      case "account_activated_dalarna":
        container.replaceChildren(PageAccountActivatedDalarna(userData));
      break;
      case "index":
      default:
        container.replaceChildren(PageIndex(userData));
      break;
    }
  }).catch((err) => {
    container.replaceChildren(ErrorNotification(err));
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const editUsernameMenuItem = document.querySelector(".navbar-item[data-page=edit_username]");
  editUsernameMenuItem.innerText = localize({sv: 'Ändra användarnamn', en: 'Edit username'})

  const editPasswordMenuItem = document.querySelector(".navbar-item[data-page=edit_password]");
  editPasswordMenuItem.innerText = localize({sv: 'Ändra lösenord', en: 'Edit password'})

  const editEmailMenuItem    = document.querySelector(".navbar-item[data-page=edit_email]");
  editEmailMenuItem.innerText = localize({sv: 'Ändra email', en: 'Edit email'})

  const logoutMenuItem       = document.querySelector(".navbar-item[data-page=_logout]");
  logoutMenuItem.innerText = localize({sv: 'Logga ut', en: 'Logout'})

  const initialPage = require('querystring').parse(window.location.search.substring(1)).page || "index";
  const container = document.querySelector(".page-container");
  refreshContent(container, initialPage, true);

  window.addEventListener("popstate", (ev) => {
    refreshContent(container, event.state.page, true);
  });

  document.querySelectorAll('.navbar-item').forEach((el) => {
    el.addEventListener("click", (ev) => {
      ev.preventDefault();

      const page = el.dataset.page;
      if (page === "_logout") {
        authToken.set(null);
        refreshContent(container, "index");
      } else {
        refreshContent(container, page);
      }
    });
  });

  const navbarBurger = document.querySelector('.navbar-burger');
  navbarBurger.addEventListener('click', function () {
    navbarBurger
      .classList
      .toggle('is-active');
    document
      .getElementById(navbarBurger.dataset.target)
      .classList
      .toggle('is-active');

  });
});

if (document.location.hostname.indexOf("aktivera.") === 0) {
  const code = window.location.hash.substring(1);
  if (code) {
    document.location = "https://account.backend.curotechlabs.com/?page=activate_account&code=" + code;
  } else {
    document.location = "https://account.backend.curotechlabs.com/?page=activate_account";
  }
}
if (document.location.hostname.indexOf("dalarna.") === 0) {
  const code = window.location.hash.substring(1);
  if (code) {
    document.location = "https://account.backend.curotechlabs.com/?page=activate_account_dalarna&code=" + code;
  } else {
    document.location = "https://account.backend.curotechlabs.com/?page=activate_account_dalarna";
  }
}
if (document.location.hostname.indexOf("enkat-dalarna.") === 0) {
  const code = window.location.hash.substring(1);
  if (code) {
    document.location = "https://account.backend.curotechlabs.com/?page=enkat_dalarna&code=" + code;
  } else {
    document.location = "https://account.backend.curotechlabs.com/?page=enkat_dalarna";
  }
}

export const bugsnagClient = Bugsnag.start({
  apiKey: '46a93d9a2cd05e8ceaa223da015b327a',
  appType: 'client',
  appVersion: process.env.APP_VERSION,
  autoTrackSessions: false,
  collectUserIp: false,
  enabledReleaseStages: ['production'],
  releaseStage: process.env.NODE_ENV,
  onError: function (event) {
    if (event.originalError && event.originalError.userDisplayableErrorMessage) {
      event.addMetadata('User Facing Error', {
        message: event.originalError.userDisplayableErrorMessage
      })
    }
  }
});
