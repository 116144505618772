<template lang="pug">
.field
  .control
    h6.title.is-6 {{title}}

    .select(:class="error ? 'is-danger' : ''")
      select(v-model="modelValue", required, @change="selectionChanged", :disabled="disabled")
        option(v-for="(option, optionIdx) in options", :key="optionIdx", :value="option.value") {{option.label}}

    p.help.is-danger(v-if="error")
      | {{error}}
</template>

<script>
export default {
  props: {
    title: String,
    error: String,
    disabled: Boolean,
    noValueLabel: String,
    minValueLabel: String,
    maxValueLabel: String,
    modelValue: String
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: [
        { label: this.noValueLabel,  value: null },
        { label: this.minValueLabel, value: "0"  },
        { label: "1",                value: "1"  },
        { label: "2",                value: "2"  },
        { label: "3",                value: "3"  },
        { label: "4",                value: "4"  },
        { label: "5",                value: "5"  },
        { label: "6",                value: "6"  },
        { label: "7",                value: "7"  },
        { label: "8",                value: "8"  },
        { label: "9",                value: "9"  },
        { label: this.maxValueLabel, value: "10" }
      ]
    };
  },
  methods: {
    selectionChanged() {
      this.$emit('update:modelValue', this.modelValue);
    }
  }
}
</script>

<style lang="scss" scoped>
  h6.title.is-6 {
    padding-top: 0.8em;
    margin-bottom: 0.5em;
  }
</style>
