import template from './index.html';

import localize from 'localize';

import { patchLoginRestoration } from 'api/tmyUsersV1';

import PasswordConfirmationValidator from 'formValidator/passwordConfirmation'

import ErrorNotification from 'components/notification/error';
import SuccessNotification from 'components/notification/success';

export default function Setup() {
  const qs = require('querystring').parse(window.location.search.substring(1));
  if (!qs.restore_token) {
    return ErrorNotification(localize({
      en: "Incorrect password restore link.",
      sv: "Inkorrekt lösenordsåterställningslänk."
    }), false);
  }

  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize({
      sv: {
        title: 'Återställ lösenord',
        password: 'Lösenord',
        passwordConfirmation: 'Lösenordsbekräftelse',
        submit: 'Återställ lösenord'
      },
      en: {
        title: 'Restore Password',
        password: 'Password',
        passwordConfirmation: 'Password confirmation',
        submit: 'Restore Password'
      }
    })
  });


  const form = container.querySelector("form");
  const passwordField = form.querySelector("input[name=password]");
  const passwordConfirmationField = form.querySelector("input[name=password_confirmation]");

  const passwordConfirmationValidator = PasswordConfirmationValidator(passwordConfirmationField, passwordField);
  passwordConfirmationField.addEventListener("change", passwordConfirmationValidator);
  passwordField.addEventListener("change", passwordConfirmationValidator);

  const errorContainer = container.querySelector(".error-container");
  form.addEventListener("submit", async (ev) => {
    ev.preventDefault();

    errorContainer.removeAllChildren();

    passwordConfirmationField.disabled = true;
    passwordField.disabled = true;

    const button = container.querySelector("button");
    button.disabled = true;
    button.classList.add("is-loading");

    try {
      await patchLoginRestoration(qs.restore_token, passwordField.value);
      const notice = SuccessNotification(localize({
        en: "Your password has been updated!",
        sv: "Ditt lösenord har uppdaterats!"
      }), false);
      form.parentNode.replaceChild(notice, form);
    } catch (err) {
      let errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Something went wrong, maybe you link is expired",
        sv: "Något gick fel, kanske är din länk för gammal."
      });

      errorContainer.appendChild(ErrorNotification(errorMsg, true));
      passwordConfirmationField.disabled = false;
      passwordField.disabled = false;
      button.disabled = false;
      button.classList.remove("is-loading");
    }
  });

  return container;
}
