<template lang="pug">
div
  h2.subtitle {{t.title}}
  .content
    p {{t.welcome}}
    p {{t.contact_doctor_info}}

  .notification.is-success(v-if="answersSubmitted")
    | {{t.answersSubmitted}}

  form(novalidate, v-if="!answersSubmitted" @submit.prevent="submit($event.target)")
    .field
      .control.has-icons-left
        input.input(
          name='activation_code',
          type='text',
          :placeholder='t.activationCode',
          :class="activationCodeError ? 'is-danger' : ''"
          required,
          minlength='14',
          maxlength='14',
          autocapitalize='characters'
          autocomplete='off',
          autocorrect='off',
          spellcheck='false',
          v-model="activationCode"
        )
        span.icon.is-small.is-left
          i.fa.fa-barcode
      p.help.is-danger(v-if="activationCodeError")
        | {{activationCodeError}}

    div.survey(v-for="(survey, surveyIdx) in [surveyTummyLab, surveySymptoms]" :key="surveyIdx")
      h3.title.is-5 {{survey.t.title}}
      p.subtitle.is-6(v-html="survey.t.instructions")

      survey-question(
        v-for="(question, questionIdx) in survey.questions", :key="questionIdx",
        :title="question.t.title",
        :disabled="submitting",
        :error="question.error",
        :no-value-label="survey.t.noValueLabel",
        :min-value-label="survey.t.minValueLabel",
        :max-value-label="survey.t.maxValueLabel",
        v-model="question.value"
      )

    .submit-container
      .notification.is-danger(v-if="serverError")
        button.delete(aria-label="delete", @click="serverError = null")
        | {{serverError}}

      .field
        .control
          button.button.is-success(type="submit", :disabled="submitting")
            | {{t.submit}}
</template>

<script>
import SurveyQuestion from './survey_question.vue';

import { bugsnagClient } from 'app';
import { RegionDalarnaFinal } from 'tummylab-protobuf/js/api.tmy.v1/web_surveys/region_dalarna_final_pb';
import { postWebSurveyResponseRegionDalarnaFinal as postResponse } from 'api/tmyV1';

export default {
  components: { SurveyQuestion },
  props: {
    activationCode: String
  },
  data: function() {
    const buildQuestion = (title, serialize) => {
      return {
        t: { "title": title },
        "error": null,
        "value": null,
        "serialize": serialize
      };
    }
    return {
      serverError: null,
      activationCodeError: null,
      answersSubmitted: false,
      submitting: false,
      t: {
        title: 'Slutenkät för Tummy Lab',
        welcome: 'Dina svar kommer att noteras i journalen. Din kontakt med Primärvårdsdietisterna i Region Dalarna är nu avslutad.',
        contact_doctor_info: 'Om du fortfarande har svåra besvär som du inte kan hantera eller upplever nya svåra symtom så föreslår vi att du tar kontakt med din läkare för diskussion om eventuell annan behandling.',
        activationCode: 'Aktiveringskod',
        valueRequired: "Måste anges!",
        submit: 'Skicka in dina svar',
        answersSubmitted: "Tack, dina svar har registrerats!"
      },
      surveyTummyLab: {
        t: {
          title: 'Utbildningsprogrammet',
          instructions: 'Vi skulle vilja veta lite mer om din upplevelse av att använda Tummy Lab.<br>För varje påstående, välj om det stämmer för dig och vi vilken grad.',
          noValueLabel: '-- Välj -- ',
          minValueLabel: 'Stämmer inte',
          maxValueLabel: 'Stämmer helt'
        },
        questions: [
          buildQuestion(
            'Jag är nöjd med Tummy Lab',
            (response, value) => response.setNojdMedTummylab(value)
          ),
          buildQuestion(
            'Det var det enkelt att förstå hur man skulle använda Tummy Lab',
            (response, value) => response.setLattAttForstaTummylab(value)
          ),
          buildQuestion(
            'Jag skulle rekommendera Tummy Lab till andra med IBS',
            (response, value) => response.setRekommenderaTummylab(value)
          ),
          buildQuestion(
            'Efter programmet kan jag hantera min sjukdom bättre',
            (response, value) => response.setKanHanteraMinSjukdomBattre(value)
          ),
        ]
      },
      surveySymptoms: {
        t: {
          title: 'Symtominventering',
          instructions: 'Vi vill gärna veta vilka symtom du haft de <strong>senaste 2 veckorna</strong>.<br>Välj för varje symtom om du haft det och i vilken grad.',
          noValueLabel: '-- Välj -- ',
          minValueLabel: 'Inte alls',
          maxValueLabel: 'Väldigt mycket'
        },
        questions: [
          buildQuestion(
            'Buksmärtor',
            (response, value) => response.setBuksmartor(value)
          ),
          buildQuestion(
            'Diarré',
            (response, value) => response.setDiarre(value)
          ),
          buildQuestion(
            'Förstoppning',
            (response, value) => response.setForstoppning(value)
          ),
          buildQuestion(
            'Uppblåsthet/Gaser',
            (response, value) => response.setUppblasthetGaser(value)
          ),
          buildQuestion(
            'Hur lite/mycket har tarmbesvären påverkat ditt dagliga liv?',
            (response, value) => response.setLivspaverkan(value)
          ),
        ]
      },
    };
  },
  methods: {
    validateSurvey(survey) {
      let hasErrors = false;

      survey.questions.forEach((question) => {
        if (question.value !== null && question.value !== undefined) {
          question.error = null;
        } else {
          question.error = this.t.valueRequired;
          hasErrors = true;
        }
      });

      return !hasErrors;
    },
    async submit(form) {
      this.serverError = null;

      let hasErrors = false;

      if (this.activationCode.length != 14) {
          this.activationCodeError = this.t.valueRequired;
          hasErrors = true;
      } else {
          this.activationCodeError = null;
      }

      if (!this.validateSurvey(this.surveyTummyLab)) {
        hasErrors = true;
      }

      if (!this.validateSurvey(this.surveySymptoms)) {
        hasErrors = true;
      }

      if (!form.checkValidity()) {
        form.reportValidity();
        return;
      }

      if (hasErrors) {
        return;
      }

      const response = new RegionDalarnaFinal();
      this.surveyTummyLab.questions.forEach((question) => question.serialize(response, question.value));
      this.surveySymptoms.questions.forEach((question) => question.serialize(response, question.value));

      try {
        if (this.submitting) { return; }
        this.submitting = true;

        await postResponse(this.activationCode, response)
        this.answersSubmitted = true;
      } catch(err) {
        this.serverError = err.userDisplayableErrorMessage || this.$localize({
          en: "Something went wrong, please try again.",
          sv: "Något gick fel, var god försök igen."
        });
        bugsnagClient.notify(err);
      } finally {
        this.submitting = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-container {
  .notification.is-danger {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.survey {
  & > h3.title.is-5 {
    margin-top: 1.5em;
  }

  & > p.subtitle.is-6 {
    padding-top: 0.2em;
    margin-bottom: 0;
  }
}

.submit-container {
  margin-top: 1.5rem;
}
</style>
