import * as rh from './_responseHandlers';
import * as tmy from './_tmyHelpers';
import * as authToken from '../authToken';

import * as responses from 'tummylab-protobuf/js/api.tmy.v1/responses_pb';
import * as requests from 'tummylab-protobuf/js/api.tmy.v1/requests_pb';

const API_NAME = "tmy.v1";

export function postWebSurveyResponseRegionDalarnaFinal(activationCode, surveyResponse) {
  const msg = new requests.PostWebSurveyResponses();
  msg.setUserActivationCode(activationCode);
  msg.setRegionDalarnaFinalSurveyResponse(surveyResponse);

  return tmy.post("/web_survey_responses", msg.serializeBinary(), {
    apiName: API_NAME,
    messageName: "API.TMY.V1.Requests.PostWebSurveyResponses"
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.V1.Responses.PostWebSurveyResponses", responses.PostWebSurveyResponses))
    .then((resp) => {
      if (!resp.getIsSuccess()) {
          const err = new Error("Failed to register web survey");
          err.userDisplayableErrorMessage = resp.getError();
          err.response = resp;
          throw err;
      }
    });
}
