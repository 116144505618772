import template_std from './index.html';
import template_c19 from './index-c19.html';

import { bugsnagClient } from 'app';
import { loginPlatformToken } from 'config'; 
import { postUsers, postLogin, patchUserResearchProjectProfiles } from 'api/tmyUsersV1';

import localize from 'localize';

import PasswordConfirmationValidator from 'formValidator/passwordConfirmation'

import ErrorNotification from 'components/notification/error';
import SuccessNotification from 'components/notification/success';

const strings = (isCovid) => {
  return {
    title: 'Aktivera Konto',
    welcome: 'Välkommen till Tummy Lab, utbildningsprogrammet som ger dig kunskap och verktyg för att bättre hantera din IBS.',
    supportInfoAndLink: 'Har du frågor eller behöver hjälp att komma igång?<br> Skicka ett email till: <a href="mailto:support@tummylab.com?subject=Aktivera%20konto">support@tummylab.se</a>',
    instruction: 'För att aktivera ditt konto, fyll i formuläret och skicka in det.',
    instructionCovid19: 'Du som fått programmet via ditt försäkringsbolag eller vårdgivare bör använda den aktiveringslänk som skickas till dig via SMS. Detta då den kan ge dig tillgång till ytterligare tjänster, såsom kliniskt underlag till din läkare',
    activationCode: 'Aktiveringskod',

    createCredentials: 'Skapa användaruppgifter',
    createCredentialsInstructions: 'Du kommer använda dessa för att logga in i appen.',
    email: 'Email (Användarnamn)',
    password: 'Lösenord',
    passwordConfirmation: 'Lösenordsbekräftelse',
    ageConfirmation: 'Jag är 18 år eller äldre, alternativt går programmet tillsammans med min förmyndare.',
    submit: 'Aktivera',
    warningsHeader: 'Om dina mag-tarm besvär är nya och odiagnoserade, eller om du är över 50 år, råder vi dig att först söka rådgivning hos 1177 för dina besvär. Vi råder dig även söka 1177 om du upplever något av följande alarmsymtom:',
    warningsSymptoms: [
      'Blod i avföringen eller rektal blödning',
      'Oförklarlig viktnedgång',
      'Nattliga besvär',
      'Feber'
    ]
  };
};

export default function Setup(then) {
  const paramActivationCode = require('querystring').parse(window.location.search.substring(1)).code;
  const isCovid19 = (paramActivationCode == 'TUMMY-LAB-COVID19');
  const template = isCovid19 ? template_c19 : template_std;

  const container = document.createElement("div");
  container.innerHTML = template({
    activationCode: paramActivationCode,
    t: localize({
      sv: strings(isCovid19),
      // TODO: localize (but we only run site in swedish so not all that important)
      en: strings(isCovid19)
    })
  });

  const form = container.querySelector("form");
  const activationCodeField = form.querySelector("input[name=activation_code]");
  const passwordConfirmationField = form.querySelector("input[name=password_confirmation]");
  const passwordField = form.querySelector("input[name=password]");
  const emailField = form.querySelector("input[name=email]");
  const ageConfirmationField = form.querySelector("input[name=age_confirmation]");

  const onInput = (ev) => {
    ev.preventDefault();

    const submittable = activationCodeField.value.length > 0
      && passwordConfirmationField.value.length > 0
      && passwordField.value.length > 0
      && emailField.value.length > 0
      && (ageConfirmationField == null || ageConfirmationField.checked);
    form.querySelector("button").disabled = !submittable;
  };

  form.querySelectorAll("input").forEach(input => {
    input.addEventListener("input", onInput);
    // Needed for checkbox in IE11
    input.addEventListener("change", onInput);
  });

  const passwordConfirmationValidator = PasswordConfirmationValidator(passwordConfirmationField, passwordField);
  passwordConfirmationField.addEventListener("change", passwordConfirmationValidator);
  passwordField.addEventListener("change", passwordConfirmationValidator);

  form.addEventListener("submit", async (ev) => {
    ev.preventDefault();

    const errorContainer = container.querySelector(".error-container");
    errorContainer.removeAllChildren();

    activationCodeField.disabled = true;
    passwordConfirmationField.disabled = true;
    passwordField.disabled = true;
    emailField.disabled = true;
    if (ageConfirmationField) {
      ageConfirmationField.disabled = true;
    }

    const button = container.querySelector("button");
    button.disabled = true;
    button.classList.add("is-loading");

    try {
      const activationCode = activationCodeField.value;
      const username = emailField.value;
      const password = passwordField.value;
      const email = emailField.value;

      await postUsers(username, password, email, activationCode, Date.now() / 1000);
      const loginResponse = await postLogin(
        username,
        password,
        loginPlatformToken
      );
      const authToken = loginResponse.getAuthToken();

      then(authToken);
    } catch (err) {
      let errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Something went wrong, please try again.",
        sv: "Något gick fel, var god försök igen."
      });

      errorContainer.appendChild(ErrorNotification(errorMsg, true));
      activationCodeField.disabled = false;
      passwordConfirmationField.disabled = false;
      passwordField.disabled = false;
      emailField.disabled = false;
      if (ageConfirmationField) {
        ageConfirmationField.disabled = false;
      }

      button.disabled = false;
      button.classList.remove("is-loading");

      bugsnagClient.notify(err);
    }
  });
  return container;
}
