import { createApp } from 'vue';
import localize from 'localize';

export default function(component, props) {
  const fragment = document.createDocumentFragment();

  const app = createApp(component, props);

  app.config.globalProperties.$localize = localize;

  app.mount(fragment);

  return fragment;
}
