<template lang="pug">
div
  h2.subtitle Tummy Lab
  .content
    p
      | {{t.loggedInAs}} <span class="tag">{{username}}</span>.
      | {{t.pickAnOption}}
</template>

<script>
export default {
  props: {
    username: String
  },
  data: function() {
    return {
      t: this.$localize({
        en: {
          loggedInAs: "You are logged in as",
          pickAnOption: "Please pick an option in the menu to proceed."
        },
        sv: {
          loggedInAs: "Du är inloggad som",
          pickAnOption: "Välj ett alternativ i menyn för att fortsätta."
        }
      })
    };
  }
}
</script>

<style lang="scss" scoped>
</style>
