import template from './index.html';

import localize from 'localize';

export default function Setup(question, minValueLabel, maxValueLabel, onChange) {
  const container = document.createElement("div");
  container.innerHTML = template({
    question: question,
    minValueLabel: minValueLabel,
    maxValueLabel: maxValueLabel,
    t: localize({
      en: {
        pick: "Pick"
      },
      sv: {
        pick: "Välj"
      }
    })
  });
  container.value = undefined;
  const selector = container.querySelector('select');
  selector.addEventListener('change', (ev) => {
    ev.preventDefault();

    if (selector.value != "-1") {
      container.value = selector.value;
    } else {
      container.value = undefined;
    }
    onChange(ev);
  });
  return container
}
