import template from './index.html';

import { bugsnagClient } from 'app';
import { loginPlatformToken } from 'config'; 
import { postUsers, postLogin } from 'api/tmyUsersV1';

import { RegionDalarnaSurveyAnswers } from 'tummylab-protobuf/js/api.tmy.users.v1/region_dalarna_survey_answers_pb';

import localize from 'localize';

import PasswordConfirmationValidator from 'formValidator/passwordConfirmation'

import ErrorNotification from 'components/notification/error';
import SuccessNotification from 'components/notification/success';

import SurveyQuestion from 'components/survey_question';

const strings = {
  title: 'Aktivera Konto',
  welcome: 'Välkommen till Tummy Lab, utbildningsprogrammet som ger dig kunskap och verktyg för att bättre hantera din IBS.',
  supportInfoAndLink: 'Har du frågor eller behöver hjälp att komma igång?<br> Skicka ett email till: <a href="mailto:support@tummylab.com?subject=Aktivera%20konto">support@tummylab.se</a>',
  instruction: 'För att aktivera ditt konto, fyll i formuläret och skicka in det.',
  activationCode: 'Aktiveringskod',

  answerSurvey: 'Symtominventering',
  answerSurveyInstructions: 'Vi vill gärna veta vilka symtom du haft de <strong>senaste 2 veckorna</strong>.<br>Välj för varje symtom om du haft det och i vilken grad.',

  createCredentials: 'Skapa användaruppgifter',
  createCredentialsInstructions: 'Du kommer använda dessa för att logga in i appen.',
  username: 'Användarnamn',
  password: 'Lösenord',
  passwordConfirmation: 'Lösenordsbekräftelse',

  dataShareInformation: 'Dina svar journalförs av primärvårdsdietist i Region Dalarna enligt rutiner som vid ett vanligt dietistbesök.',

  username_not_editable: "Användarnamn (ej redigerbart)",

  submit: 'Aktivera',
}

export default function Setup(then) {
  const paramActivationCode = require('querystring').parse(window.location.search.substring(1)).code;

  const container = document.createElement("div");
  container.innerHTML = template({
    activationCode: paramActivationCode,
    t: localize({
      sv: strings,
      // TODO: localize (but we only run site in swedish so not all that important)
      en: strings
    })
  });

  const form = container.querySelector("form");
  const activationCodeField = form.querySelector("input[name=activation_code]");
  const passwordConfirmationField = form.querySelector("input[name=password_confirmation]");
  const passwordField = form.querySelector("input[name=password]");

  const usernameField = form.querySelector("input[name=username]");

  const onInput = (ev) => {
    ev.preventDefault();

    usernameField.value = activationCodeField.value;

    const submittable = activationCodeField.value.length > 0
      && passwordConfirmationField.value.length > 0
      && passwordField.value.length > 0
      && q1.value !== undefined 
      && q2.value !== undefined
      && q3.value !== undefined
      && q4.value !== undefined
      && q5.value !== undefined;
    form.querySelector("button").disabled = !submittable;
  };

  const containerSurveyQuestions = container.querySelector(".containerSurveyQuestions");

  const minValueLabel = localize({
    sv: 'Inte alls',
    en: 'Inte alls' // TODO: localize
  });
  const maxValueLabel = localize({
    sv: 'Väldigt mycket',
    en: 'Väldigt mycket' // TODO: localize
  });

  const q1Label = localize({
    sv: 'Buksmärtor',
    en: 'Buksmärtor' // TODO: localize
  });
  const q1 = SurveyQuestion(q1Label, minValueLabel, maxValueLabel, onInput);
  containerSurveyQuestions.appendChild(q1);

  const q2Label = localize({
    sv: 'Diarré',
    en: 'Diarré' // TODO: localize
  });
  const q2 = SurveyQuestion(q2Label, minValueLabel, maxValueLabel, onInput);
  containerSurveyQuestions.appendChild(q2);

  const q3Label = localize({
    sv: 'Förstoppning',
    en: 'Förstoppning' // TODO: localize
  });
  const q3 = SurveyQuestion(q3Label, minValueLabel, maxValueLabel, onInput);
  containerSurveyQuestions.appendChild(q3);

  const q4Label = localize({
    sv: 'Uppblåsthet/Gaser',
    en: 'Uppblåsthet/Gaser' // TODO: localize
  });
  const q4 = SurveyQuestion(q4Label, minValueLabel, maxValueLabel, onInput);
  containerSurveyQuestions.appendChild(q4);

  const q5Label = localize({
    sv: 'Hur lite/mycket har tarmbesvären påverkat ditt dagliga liv?',
    en: 'Hur lite/mycket har tarmbesvären påverkat ditt dagliga liv?' // TODO: localize
  });
  const q5 = SurveyQuestion(q5Label, minValueLabel, maxValueLabel, onInput);
  containerSurveyQuestions.appendChild(q5);

  form.querySelectorAll("input").forEach(input => {
    input.addEventListener("input", onInput);
    // Needed for checkbox in IE11
    input.addEventListener("change", onInput);
  });

  const passwordConfirmationValidator = PasswordConfirmationValidator(passwordConfirmationField, passwordField);
  passwordConfirmationField.addEventListener("change", passwordConfirmationValidator);
  passwordField.addEventListener("change", passwordConfirmationValidator);

  form.addEventListener("submit", async (ev) => {
    ev.preventDefault();

    const errorContainer = container.querySelector(".error-container");
    errorContainer.removeAllChildren();

    activationCodeField.disabled = true;
    passwordConfirmationField.disabled = true;
    passwordField.disabled = true;

    const button = container.querySelector("button");
    button.disabled = true;
    button.classList.add("is-loading");

    try {
      const activationCode = activationCodeField.value;
      const username = activationCodeField.value;
      const password = passwordField.value;

      const regionDalarnaSurveyAnswers = new RegionDalarnaSurveyAnswers();
      regionDalarnaSurveyAnswers.setBuksmartor(q1.value);
      regionDalarnaSurveyAnswers.setDiarre(q2.value);
      regionDalarnaSurveyAnswers.setForstoppning(q3.value);
      regionDalarnaSurveyAnswers.setUppblasthetGaser(q4.value);
      regionDalarnaSurveyAnswers.setLivspaverkan(q5.value);

      await postUsers(
        username,
        password,
        null,
        activationCode,
        Date.now() / 1000,
        regionDalarnaSurveyAnswers
      );
      const loginResponse = await postLogin(
        username,
        password,
        loginPlatformToken
      );
      const authToken = loginResponse.getAuthToken();

      then(authToken);
    } catch (err) {
      let errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Something went wrong, please try again.",
        sv: "Något gick fel, var god försök igen."
      });

      errorContainer.appendChild(ErrorNotification(errorMsg, true));
      activationCodeField.disabled = false;
      passwordConfirmationField.disabled = false;
      passwordField.disabled = false;

      button.disabled = false;
      button.classList.remove("is-loading");

      bugsnagClient.notify(err);
    }
  });
  return container;
}
